var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticStyle: { "background-color": "#fafafa", "box-shadow": "none" },
      attrs: { id: "page-header" }
    },
    [
      _vm._t("default", [
        _c(
          "div",
          {
            staticClass: "content-header",
            staticStyle: { "margin-left": "15px" }
          },
          [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "base-layout-modifier",
                  {
                    staticClass: "mr-2 d-lg-none",
                    attrs: {
                      action: "sidebarToggle",
                      size: "sm",
                      variant: "dual"
                    }
                  },
                  [_c("i", { staticClass: "fa fa-fw fa-bars" })]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "mr-2 d-lg-none d-flex" },
                  [
                    _c("img", {
                      staticClass: "cursor-pointer",
                      staticStyle: { height: "30px" },
                      attrs: { src: require("@/assets/icons/logo-min.svg") },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "Dashboard" })
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("AddToHomeScreen")
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "base-layout-modifier",
                  {
                    staticClass: "mr-2 d-none d-lg-inline-block",
                    attrs: {
                      action: "sidebarMiniToggle",
                      size: "sm",
                      variant: "dual"
                    }
                  },
                  [_c("i", { staticClass: "fa fa-fw fa-ellipsis-v" })]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("div", [
                  _c("i", {
                    class: [
                      "fas",
                      _vm.$store.state.app.darkmodea ? "fa-sun" : "fa-moon"
                    ],
                    on: {
                      click: function($event) {
                        return _vm.gl_switchDarkMode()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "b-dropdown",
                  {
                    staticClass: "d-inline-block ml-2",
                    attrs: {
                      size: "sm",
                      variant: "dual",
                      "menu-class":
                        "dropdown-menu-lg p-0 border-0 font-size-sm",
                      right: "",
                      "no-caret": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("i", { staticClass: "fa fa-fw fa-bell" }),
                            _vm._v(" "),
                            _vm.notifications.length
                              ? _c("span", { staticClass: "text-primary" }, [
                                  _vm._v("•")
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c("li", [
                      _c("div", { staticClass: "p-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "font-common",
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "12px"
                            }
                          },
                          [
                            _vm._v(
                              "\n                Notifikasi\n              "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "nav-items mb-0",
                          staticStyle: { "max-width": "100vw" }
                        },
                        [
                          _vm._l(_vm.notifications, function(
                            notification,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: "notification-" + index,
                                staticClass: "border-bottom"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex px-2 py-1",
                                    staticStyle: {
                                      "justify-content": "space-between",
                                      cursor: "pointer"
                                    },
                                    style: _vm.getColor(notification.n_read),
                                    on: {
                                      click: function($event) {
                                        return _vm.setRead(notification)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", {}, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "font-common",
                                          staticStyle: {
                                            "font-weight": "bold",
                                            "font-size": "12px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(notification.n_title) +
                                              "\n                      "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "font-common ml-2",
                                              staticStyle: {
                                                "font-size": "10px",
                                                color: "#cccccc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    notification.created_at
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "font-common",
                                        staticStyle: { "font-size": "12px" },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            notification.n_content
                                          )
                                        }
                                      })
                                    ])
                                  ]
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          !_vm.notifications.length
                            ? _c("li", { staticClass: "p-2" }, [
                                _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-0 font-common",
                                      staticStyle: { "font-size": "12px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Tidak Ada Notifikasi\n                  "
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.notifications.length
                        ? _c("div", { staticClass: "p-2 border-top" }, [
                            _c("div", { staticClass: "text-center py-1" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "font-common",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    color: "#008eb7",
                                    cursor: "pointer"
                                  },
                                  on: { click: _vm.toNotifikasi }
                                },
                                [
                                  _vm._v(
                                    "\n                  Lihat Selengkapnya\n                "
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown",
                  {
                    staticClass: "d-inline-block ml-2",
                    attrs: {
                      size: "sm",
                      variant: "dual",
                      "menu-class": "p-0 font-size-sm",
                      right: "",
                      "no-caret": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.name) +
                                  "\n              "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "b-dropdown-item",
                      { attrs: { href: "#" }, on: { click: _vm.pengaturan } },
                      [_vm._v("Akun")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-dropdown-item",
                      { attrs: { href: "#" }, on: { click: _vm.logout } },
                      [_vm._v("Logout")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }