<template>
  <ul :class="classContainer">
    <li
      v-for="(node, index) in nodes"
      :key="`node-${index}`"
      :class="{
        'nav-main-item': !node.heading,
        open:
          node.sub && node.subActivePaths
            ? subIsActive(node.subActivePaths)
            : false
      }"
    >
      <template v-if="checkHeader(node.name)">
        <div class="nav-header-text">
          <span class="">
            {{ node.heading ? node.name : "" }}
          </span>
        </div>
      </template>

      <template v-if="getPermissions(node.permission)">
        <router-link
          v-if="!node.heading && !node.sub"
          :to="node.to || '#'"
          class="nav-main-link"
          @click.native="linkClicked($event, index)"
          :id="'nav-' + index"
          style="
            display: flex;
            justify-content: space-between;
            background: none;
            text-decoration: none;
            color: #ffffff;
          "
        >
          <div>
            <div class="nav-icon-custom">
              <i
                v-if="node.icon"
                :id="'nav-icon-' + index"
                :class="`nav-main-link-icon ${node.icon}`"
                style="color: #ffffff"
              />
            </div>
            <span v-if="node.name" class="nav-main-link-name">
              {{ node.name }}
            </span>
          </div>

          <template v-if="$store.getters.sbNotifications">
            <div
              v-if="
                node.notifkey &&
                  $store.getters.sbNotifications[node.notifkey] !== 0
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 20px;
                  width: 20px;
                  background: #ea3e3a;
                  border-radius: 50%;
                  border: none;
                  outline: none;
                "
              >
                <span style="color: #ffffff !important">
                  {{ $store.getters.sbNotifications[node.notifkey] }}
                </span>
              </div>
            </div>
          </template>
        </router-link>
      </template>

      <a
        v-else-if="!node.heading && node.sub"
        href="#"
        class="nav-main-link nav-main-link-submenu"
        @click.prevent="linkClicked($event, true)"
      >
        <i v-if="node.icon" :class="`nav-main-link-icon ${node.icon}`"></i>
        <span v-if="node.name" class="nav-main-link-name">{{ node.name }}</span>
        <span
          v-if="node.badge"
          class="nav-main-link-badge badge badge-pill badge-primary"
          :class="
            node['badge-variant']
              ? `badge-${node['badge-variant']}`
              : 'badge-primary'
          "
          >{{ node.badge }}</span
        >
      </a>

      <base-navigation
        v-if="node.sub"
        :nodes="node.sub"
        sub-menu
      ></base-navigation>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BaseNavigation",

  components: {},

  props: {
    nodes: {
      type: Array,
      description: "The nodes of the navigation"
    },
    subMenu: {
      type: Boolean,
      default: false,
      description: "If true, a submenu will be rendered"
    },
    dark: {
      type: Boolean,
      default: false,
      description: "Dark mode for menu"
    },
    horizontal: {
      type: Boolean,
      default: false,
      description: "Horizontal menu in large screen width"
    },
    horizontalHover: {
      type: Boolean,
      default: false,
      description: "Hover mode for horizontal menu"
    },
    horizontalCenter: {
      type: Boolean,
      default: false,
      description: "Center mode for horizontal menu"
    },
    horizontalJustify: {
      type: Boolean,
      default: false,
      description: "Justify mode for horizontal menu"
    }
  },

  data: () => ({
    // sidebarNotifications: $store.getters.sbNotifications
  }),

  computed: {
    classContainer() {
      return {
        "nav-main": !this.subMenu,
        "nav-main-submenu": this.subMenu,
        "nav-main-dark": this.dark,
        "nav-main-horizontal": this.horizontal,
        "nav-main-hover": this.horizontalHover,
        "nav-main-horizontal-center": this.horizontalCenter,
        "nav-main-horizontal-justify": this.horizontalJustify
      };
    }
  },

  created() {
    // localStorage.setItem('nav', 0)
    setTimeout(() => {
      this.setActiveStateOnRefresh();
    }, 300);
  },

  methods: {
    subIsActive(paths) {
      const activePaths = Array.isArray(paths) ? paths : [paths];

      return activePaths.some(path => {
        return this.$route.path.indexOf(path) === 0;
      });
    },

    setActiveStateOnRefresh() {
      let i = localStorage.getItem("nav");
      document.getElementById("nav-" + i).style.background = "#F2F2F2";
      document.getElementById("nav-" + i).style.color = "#008EB7";
      document.getElementById("nav-icon-" + i).style.color = "#008EB7";
    },

    linkClicked(e, submenu) {
      let windowW =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      if (windowW < 991) {
        this.$store.commit("sidebar", { mode: "close" });
      }

      let i = submenu;

      let prevNavIndex = localStorage.getItem("nav");
      document.getElementById("nav-" + prevNavIndex).style.background = "none";
      document.getElementById("nav-" + prevNavIndex).style.color = "#FFFFFF";
      document.getElementById("nav-icon-" + prevNavIndex).style.color =
        "#FFFFFF";
      document.getElementById("nav-" + prevNavIndex).style.fontWeight =
        "normal";
      // vs
      localStorage.setItem("nav", i);
      document.getElementById("nav-" + i).style.background = "#F2F2F2";
      document.getElementById("nav-" + i).style.color = "#008EB7";
      document.getElementById("nav-icon-" + i).style.color = "#008EB7";
      document.getElementById("nav-" + i).style.fontWeight = "bold";

      if (submenu) {
        let el = e.target.closest("li");
        if (!(windowW > 991 && this.horizontal && this.horizontalHover)) {
          if (el.classList.contains("open")) {
            el.classList.remove("open");
          } else {
            el.closest("ul").children.forEach(element => {
              element.classList.remove("open");
            });

            el.classList.add("open");
          }
        }
      } else {
        if (windowW < 992) {
          this.$store.commit("sidebar", { mode: "close" });
        }
      }
    },

    getPermissions(key) {
      if (key === "ALL") return true;
      return this.gl_check_permission(key);
    },

    checkHeader(key) {
      const header = key.toUpperCase();

      if (header === "FAT") {
        if (
          this.gl_check_permission("ADM_FINANCE_REIMBURSE_VIEW") ||
          this.gl_check_permission("ADM_CASHIER_PETTYCASH_VIEW") ||
          this.gl_check_permission("ADM_FINANCE_CADVANCE_VIEW") ||
          this.gl_check_permission("ADM_FINANCE_PAYMENTVOUCHER_VIEW") ||
          this.gl_check_permission("ADM_CASHIER_PETTYCASH_TOPUP_VIEW")
        ) {
          return true;
        }

        return false;
      }
      if (header === "HRGA") {
        if (
          this.gl_check_permission("SETTING_EMPLOYEE_VIEW") ||
          this.gl_check_permission("ASSIGNMENT_LETTER_VIEW") ||
          this.gl_check_permission("ATTENDANCE_REPORT_VIEW") ||
          this.gl_check_permission("DAILY_ATTENDANCE_VIEW") ||
          this.gl_check_permission("CHECKPOIN_CANVASSING_VIEW") ||
          this.gl_check_permission("MANAGER_ON_DUTY_VIEW") ||
          this.gl_check_permission("ATTENDANCE_SETTING")
        ) {
          return true;
        }

        return false;
      }
      if (header === "INFORMASI") {
        if (
          this.gl_check_permission("INFO_COMPANY_VIEW") ||
          this.gl_check_permission("INFO_CATEGORY_VIEW") ||
          this.gl_check_permission("INFO_DIVISION_VIEW") ||
          this.gl_check_permission("USER_KONTAK_KARYAWAN") ||
          this.gl_check_permission("USER_PENGUMUMAN") ||
          this.gl_check_permission("USER_KALENDER")
        ) {
          return true;
        }
        return false;
      }
      if (header === "OPERASIONAL") {
        if (
          this.gl_check_permission("VENDOR_VIEW") ||
          this.gl_check_permission("ASSET_VIEW") ||
          this.gl_check_permission("MAINTENANCE_VIEW")
        ) {
          return true;
        }
        return false;
      }
      if (header === "PENGATURAN") {
        if (
          this.gl_check_permission("SETTING_MANAGERIAL_VIEW") ||
          this.gl_check_permission("SETTING_CATEGORY_VIEW") ||
          this.gl_check_permission("SETTING_PETTYCASH_VIEW")
        ) {
          return true;
        }

        return false;
      }
    }
  }
};
</script>

<style scoped></style>
