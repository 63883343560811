import Vue from "vue";
import Router from "vue-router";

// Axios
import axios from "axios";

// Main layouts
import LayoutBackend from "@/layouts/variations/Backend.vue";
// import LayoutSimple from '@/layouts/variations/Simple.vue'

// Register Vue Router
Vue.use(Router);

// Router Configuration
const router = new Router({
  linkActiveClass: "",
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  // mode: 'hash',
  mode: "history",
  routes: [
    // Routes:
    // Login
    {
      path: "*",
      name: "NotFound",
      redirect: "/"
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/mobile/login/Login.vue")
    },

    {
      path: "/s/:id?",
      name: "redirectWA",
      component: () => import("../views/mobile/others/ForwarderPage.vue")
    },

    {
      path: "/formInstan/:id?",
      name: "formInstanEmployee",
      component: () => import("../views/mobile/others/formInstan.vue")
    },

    {
      path: "/",
      name: "Home",
      redirect: "/dashboard",
      component: LayoutBackend,
      children: [
        // Dashboard
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/views/Dashboard.vue")
        },

        // Pengaturan
        {
          path: "settings",
          name: "Settings",
          component: () => import("@/views/mobile/pengaturan/Pengaturan.vue")
        },
        {
          path: "settings/profile",
          name: "SettingProfile",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanProfile.vue")
        },
        {
          path: "settings/company",
          name: "SettingCompany",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanCompany.vue")
        },
        {
          path: "settings/bank",
          name: "SettingBank",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanBank.vue")
        },
        {
          path: "settings/password",
          name: "SettingPassword",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanPassword.vue")
        },
        {
          path: "settings/bank-3",
          name: "SettingBank3",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanBankPihak3.vue")
        },

        // Login Devices
        {
          path: "/logindevices",
          name: "LoginDevices",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanLoginDevices.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Kehadiran
        ///////////////////////////////////////////////////////////
        {
          path: "attendance",
          name: "Attendance",
          component: () => import("../views/mobile/attendance/Attendance.vue")
        },

        {
          path: "attendance/picture",
          name: "AttendancePicture",
          component: () =>
            import("../views/mobile/attendance/AttendancePicture.vue")
        },

        {
          path: "attendance/form",
          name: "AttendanceForm",
          component: () =>
            import("../views/mobile/attendance/AttendanceForm.vue")
        },

        {
          path: "attendance/history",
          name: "AttendanceHistory",
          component: () =>
            import("../views/mobile/attendance/AttendanceHistory.vue")
        },

        {
          path: "attendance/request/form",
          name: "AttendanceRequestForm",
          component: () =>
            import("../views/mobile/attendance/AttendanceRequestForm.vue")
        },

        {
          path: "attendance/admin/report",
          name: "AttendanceReport",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceReport.vue"),
          meta: {
            permission: "ATTENDANCE_REPORT_VIEW"
          }
        },
        {
          path: "attendance/admin/report2",
          name: "AttendanceReport2",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceReport2.vue"),
          meta: {
            permission: "DAILY_ATTENDANCE_VIEW"
          }
        },

        ///////////////////////////////////////////////////////////
        /// Sales Canvasser
        ///////////////////////////////////////////////////////////
        {
          path: "canvassing",
          name: "Canvassing",
          component: () => import("../views/mobile/canvassing/Attendance.vue")
        },

        {
          path: "canvassing/picture",
          name: "CanvassingPicture",
          component: () =>
            import("../views/mobile/canvassing/AttendancePicture.vue")
        },

        {
          path: "canvassing/form",
          name: "CanvassingForm",
          component: () =>
            import("../views/mobile/canvassing/AttendanceForm.vue")
        },

        {
          path: "canvassing/history",
          name: "CanvassingHistory",
          component: () =>
            import("../views/mobile/canvassing/AttendanceHistory.vue")
        },

        {
          path: "canvassing/request/form",
          name: "CanvassingRequestForm",
          component: () =>
            import("../views/mobile/canvassing/AttendanceRequestForm.vue")
        },

        // {
        //   path: "canvassing/admin/report",
        //   name: "CanvassingReport",
        //   component: () =>
        //     import("@/views/mobile/canvassing/admin/AttendanceReport.vue"),
        //   meta: {
        //     permission: "CHECKPOIN_CANVASSING_VIEW"
        //   }
        // },
        {
          path: "canvassing/admin/report2",
          name: "CanvassingReport2",
          component: () =>
            import("@/views/mobile/canvassing/admin/AttendanceReport2.vue"),
          meta: {
            permission: "CHECKPOIN_CANVASSING_VIEW" 
          }
        },
       
       
       
       
        {
          path: "mod/",
          name: "ManagerOnDuty",
          component: () =>
            import("@/views/mobile/managerOnDuty/view.vue"),
          meta: {
            permission: "MANAGER_ON_DUTY_VIEW"
          }
        },
        {
          path: "attend/admin/setting",
          name: "AttendanceSetting",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceSetting.vue"),
          meta: {
            permission: "ATTENDANCE_SETTING"
          }
        },

        // Approval Attendance
        {
          path: "/approval/attendance_request/:id?",
          name: "ApprovalAttendanceRequest",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Cuti
        ///////////////////////////////////////////////////////////
        {
          path: "timeoff",
          name: "Timeoff",
          component: () => import("../views/mobile/timeoff/Timeoff.vue")
        },

        {
          path: "timeoff/form",
          name: "TimeoffForm",
          component: () => import("../views/mobile/timeoff/TimeoffForm.vue")
        },

        // Approval TimeOff
        {
          path: "/approval/timeoff/:id?",
          name: "ApprovalTimeOff",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Reimburse
        ///////////////////////////////////////////////////////////
        {
          path: "reimburse",
          name: "Reimburse",
          component: () => import("../views/mobile/reimburse/ReimburseHome.vue")
        },
        {
          path: "reimburse/add/:id?",
          name: "ReimburseAdd",
          component: () =>
            import("../views/mobile/reimburse/ReimburseTambah.vue")
        },
        {
          path: "reimburse/detail/:id",
          name: "ReimburseDetail",
          component: () =>
            import("../views/mobile/reimburse/ReimburseDetail.vue")
        },
        {
          path: "reimburse/riwayat/:id?",
          name: "ReimburseHistory",
          component: () =>
            import("../views/mobile/reimburse/ReimburseRiwayat.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Approval
        ///////////////////////////////////////////////////////////
        {
          path: "approval",
          name: "Approval",
          component: () => import("../views/mobile/approval/Approval.vue")
        },
        {
          path: "approval/history",
          name: "ApprovalHistory",
          component: () =>
            import("../views/mobile/approval/ApprovalHistory.vue")
        },
        {
          path: "approval/topuppc/:id?",
          name: "ApprovalTopUpPettyCash",
          component: () =>
            import("../views/mobile/approval/ApprTopUpPettyCash.vue")
        },

        // Approval Cash Advance
        {
          path: "approval/pengajuan/:id?",
          name: "ApprovalCAPengajuan",
          component: () =>
            import("../views/mobile/approval/ApprCAPengajuan.vue")
        },
        {
          path: "approval/realisasi/:id?",
          name: "ApprovalCARealisasi",
          component: () =>
            import("../views/mobile/approval/ApprCARealisasi.vue")
        },

        // Cash Advance
        {
          path: "cashadvance",
          name: "CashAdvance",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceHome.vue")
        },
        {
          path: "cashadvance/add",
          name: "CashAdvanceAdd",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceTambah.vue")
        },
        {
          path: "cashadvance/history",
          name: "CashAdvanceHistory",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceRiwayat.vue")
        },
        {
          path: "cashadvance/detail/:id?",
          name: "CashAdvanceDetail",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceDetail.vue")
        },
        {
          path: "cashadvance/additional/:id?",
          name: "CashAdvanceAdditional",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceTambahPengajuan.vue")
        },
        {
          path: "cashadvance/realization/:id?",
          name: "CashAdvanceRealization",
          component: () =>
            import(
              "../views/mobile/cashadvance/CashAdvanceTambahPengeluaran.vue"
            )
        },
        {
          path: "cashadvance/realization/edit/:id?",
          name: "CashAdvanceEditRealization",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceEditPengeluaran.vue")
        },
        {
          path: "cashadvance/request/edit/:id?/:main?",
          name: "CashAdvanceEditRequest",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceEditPengajuan.vue")
        },

        // Finance
        {
          path: "finance/reimburse",
          name: "FinanceReimburse",
          component: () =>
            import("../views/mobile/finance/ApprovalReimburse.vue"),
          meta: {
            permission: "ADM_FINANCE_REIMBURSE_VIEW"
          }
        },
        {
          path: "finance/approval/:id",
          name: "FinanceApprovalReimburse",
          component: () =>
            import("../views/mobile/finance/ApprReimburseDetail.vue")
        },
        {
          path: "finance/cashadvance",
          name: "FinanceCashAdvance",
          component: () =>
            import("../views/mobile/finance/ApprovalCashAdvance.vue"),
          meta: {
            permission: "ADM_FINANCE_CADVANCE_VIEW"
          }
        },
        {
          path: "finance/cashadvance/:id?",
          name: "FinanceApprovalCashAdvance",
          component: () =>
            import("../views/mobile/finance/ApprCashAdvanceDetail.vue")
        },

        // Kasir
        {
          path: "finance/kasir",
          name: "Kasir",
          component: () => import("../views/mobile/kasir/ApprovalReimburse.vue")
        },
        {
          path: "finance/kasir/approval/:id/:ispc?",
          name: "KasirApproval",
          component: () =>
            import("../views/mobile/kasir/ApprReimburseDetail.vue")
        },

        // Payment Voucher
        {
          path: "paymentvoucher",
          name: "PaymentVoucher",
          component: () =>
            import("../views/mobile/paymentvoucher/PaymentVoucher.vue")
        },
        {
          path: "paymentvoucher/detail/:id",
          name: "PaymentVoucherDetail",
          component: () =>
            import("../views/mobile/paymentvoucher/PaymentVoucherDetail.vue")
        },

        // Employee
        {
          path: "employee",
          name: "Employee",
          component: () => import("../views/mobile/karyawan/Employee.vue"),
          meta: {
            permission: "SETTING_EMPLOYEE_VIEW"
          }
        },
        {
          path: "employee/form/:id?",
          name: "EmployeeForm",
          component: () => import("../views/mobile/karyawan/EmployeeForm.vue"),
          meta: {
            permission: "SETTING_EMPLOYEE_VIEW"
          }
        },

        // Information
        {
          path: "information/company",
          name: "InformationCompany",
          component: () => import("../views/mobile/information/Company.vue"),
          meta: {
            permission: "INFO_COMPANY_VIEW"
          }
        },
        {
          path: "information/company/add",
          name: "CompanyAdd",
          component: () => import("../views/mobile/information/CompanyAdd.vue"),
          meta: {
            permission: "INFO_COMPANY_VIEW"
          }
        },
        {
          path: "information/company/edit/:id?",
          name: "CompanyEdit",
          component: () =>
            import("../views/mobile/information/CompanyEdit.vue"),
          meta: {
            permission: "INFO_COMPANY_VIEW"
          }
        },
        {
          path: "information/company/detail/:id?",
          name: "InformationCompanyDetail",
          component: () =>
            import("../views/mobile/information/CompanyDetail.vue"),
          meta: {
            permission: "INFO_COMPANY_VIEW"
          }
        },
        {
          path: "information/category",
          name: "InformationCategory",
          component: () => import("../views/mobile/information/Category.vue")
        },
        {
          path: "information/division",
          name: "InformationDivision",
          component: () => import("../views/mobile/information/Division.vue")
        },
        {
          path: "information/contactnumber",
          name: "InformationContact",
          component: () => import("../views/mobile/information/Contact.vue")
        },
        {
          path: "information/calendar",
          name: "InformationCalendar",
          component: () => import("../views/mobile/information/Calendar.vue")
        },

        // Company
        {
          path: "company/managerial",
          name: "CompanyManagerial",
          component: () =>
            import("../views/mobile/company/CompanyManagerial.vue"),
          meta: {
            permission: "SETTING_MANAGERIAL_VIEW"
          }
        },
        {
          path: "company/category",
          name: "CompanyCategory",
          component: () =>
            import("../views/mobile/company/CompanyCategory.vue"),
          meta: {
            permission: "SETTING_CATEGORY_VIEW"
          }
        },
        {
          path: "company/pettycash",
          name: "CompanyPettyCash",
          component: () =>
            import("../views/mobile/company/CompanyPettyCash.vue"),
          meta: {
            permission: "SETTING_PETTYCASH_VIEW"
          }
        },
        {
          path: "company/pettycash/detail/:id?",
          name: "CompanyPettyCashDetail",
          component: () =>
            import("../views/mobile/company/CompanyPettyCashDetail.vue"),
          meta: {
            permission: "SETTING_PETTYCASH_VIEW"
          }
        },

        // Petty Cash
        {
          path: "pettycash",
          name: "PettyCash",
          component: () => import("../views/mobile/pettycash/PettyCash.vue"),
          meta: {
            permission: "ADM_CASHIER_PETTYCASH_TOPUP_VIEW"
          }
        },
        {
          path: "pettycash/detail/:id?",
          name: "PettyCashDetail",
          component: () =>
            import("../views/mobile/pettycash/PettyCashDetail.vue"),
          meta: {
            permission: "ADM_CASHIER_PETTYCASH_TOPUP_VIEW"
          }
        },
        {
          path: "pettycash/topup",
          name: "PettyCashTopup",
          component: () =>
            import("../views/mobile/pettycash/PettyCashTopUp.vue"),
          meta: {
            permission: "ADM_CASHIER_PETTYCASH_TOPUP_VIEW"
          }
        },

        // Announcement
        {
          path: "announcement",
          name: "Announcement",
          component: () =>
            import("../views/mobile/announcement/Announcement.vue")
        },
        {
          path: "announcement/create",
          name: "AnnouncementCreate",
          component: () =>
            import("../views/mobile/announcement/AnnouncementCreate.vue")
        },
        {
          path: "announcement/detail/:id?",
          name: "AnnouncementDetail",
          component: () =>
            import("../views/mobile/announcement/AnnouncementDetail.vue")
        },
        {
          path: "announcement/edit/:id?",
          name: "AnnouncementEdit",
          component: () =>
            import("../views/mobile/announcement/AnnouncementEdit.vue")
        },

        // Company Bank
        {
          path: "/companybank",
          name: "CompanyBank",
          component: () => import("../views/mobile/companybank/CompanyBank.vue")
        },

        // Notification
        {
          path: "/notifikasi",
          name: "Notifikasi",
          component: () => import("../views/mobile/notifikasi/Notifikasi.vue")
        },

        // Surat Penugasan
        {
          path: "/surattugas",
          name: "SuratTugas",
          component: () => import("../views/mobile/surattugas/SuratTugas.vue")
        },
        {
          path: "/surattugas/create",
          name: "SuratTugasCreate",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasCreate.vue")
        },
        {
          path: "/surattugas/detail/:id?",
          name: "SuratTugasDetail",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasDetail.vue")
        },
        {
          path: "/surattugas/history",
          name: "SuratTugasHistory",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasHistory.vue")
        },
        {
          path: "/surattugas/manage",
          name: "SuratTugasManage",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasManage.vue")
        },

        // Approval Surat Tugas
        {
          path: "/approval/surattugas/:id?",
          name: "ApprovalSuratTugas",
          component: () => import("../views/mobile/approval/ApprSuratTugas.vue")
        },

        // Change Log
        {
          path: "/changelog",
          name: "ChangeLog",
          component: () => import("../views/mobile/changelog/Changelog.vue")
        },

        //vendor
        {
          path: "/vendor",
          name: "vendor",
          component: () => import("../views/mobile/vendor/view.vue"),
          meta: {
            permission: "VENDOR_VIEW"
          }
        },

        //asset
        {
          path: "/asset",
          name: "Asset",
          component: () => import("../views/mobile/asset/view.vue")
        },

        //maintenance
        {
          path: "/maintenance",
          name: "Maintenance",
          component: () => import("../views/mobile/maintenance/view.vue")
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && to.name !== "formInstanEmployee") {
    if (localStorage.getItem("user-info") === null) {
      next({ name: "Login" });
      return;
    }

    let permission = to.meta.permission;
    if (permission != undefined) {
      const userPermissions = JSON.parse(
        localStorage.getItem("user-permissions")
      );

      if (!userPermissions.includes(to.meta.permission)) {
        next({ name: "Dashboard" });
      }
    }

    //check auth
    const api = process.env.VUE_APP_API_URL;
    const userInfo = JSON.parse(localStorage.getItem("user-info"));
    const config = {
      headers: {
        "x-api-key": userInfo.em_login_token
      }
    };

    axios
      .post(
        api + "/v1/check-auth",
        {
          app_v: process.env.VUE_APP_VERSION
        },
        config
      )
      .then(res => {
        if (res.status === 200) {
          next();
        }
      })
      .catch(error => {
        if (error.response) {
          // let data = error.response.data;
          // let header = error.response.headers;
          let code = error.response.status;
          if (code == 400 || code == 500) {
            localStorage.clear();
            next({ name: "Login" });
          }
        }
        next({ name: "Dashboard" });
      });
  } else {
    next();
  }
});

// router.afterEach((to, from) => {
//   console.log('aftereach call');
//   console.log(from);

// })

export default router;
